import React, { memo, useEffect, useState } from 'react'
import { graphql, navigate } from 'gatsby'

import { withTranslationHOC } from '../i18n/with-translation-hoc'
import { Region, Layout, Main, Header, Content } from '../components'
import { getId, UserContext, setId, appRedirect } from '../utils'
import { IChildImageSharp, IQuestEdges, IUserEdges } from '../types'

interface IndexPageProps {
  location: {
    pathname: string
    href: string
  }
  data: {
    allUsersJson: {
      edges: IUserEdges[]
    }
    allQuestsJson: {
      edges: IQuestEdges[]
    }
    connections: IChildImageSharp
  }
}

const Home = memo(
  ({
    data: {
      allUsersJson: { edges: users },
      allQuestsJson: { edges: quests },
      connections,
    },
    location,
  }: IndexPageProps) => {
    const [userID, setUserID] = useState('')
    const [user, setUser] = useState<IUserEdges | undefined>()

    useEffect(() => {
      const id = getId()
      const user = users.find(({ node: { id: nodeId } }) => nodeId === id)

      setId(id)
      setUserID(id)
      setUser(user)

      let redirectTimeout: NodeJS.Timeout

      if (id && user) {
        appRedirect()
      } else {
        redirectTimeout = setTimeout(() => {
          navigate('/unauthorised')
        }, 2000)
      }

      return () => clearInterval(redirectTimeout)
    }, [users])

    return (
      <UserContext.Provider
        value={{
          id: userID,
          user: user?.node,
        }}
      >
        <Layout location={location}>
          <Region pathname={location.pathname} />

          <Main>
            <Header pathname={location.pathname} />
            {user && <Content connectionsImage={connections} quests={quests} />}
          </Main>
        </Layout>
      </UserContext.Provider>
    )
  }
)

Home.displayName = 'Home'

export default withTranslationHOC(Home)

export const indexPageQuery = graphql`
  query IndexPageQuery {
    allUsersJson {
      edges {
        node {
          id
          name
          role
          image {
            ...fluidImage
          }
        }
      }
    }
    allQuestsJson {
      edges {
        node {
          name
          image {
            ...fluidImage
          }
        }
      }
    }
    connections: file(relativePath: { eq: "connections.png" }) {
      ...fluidImage
    }
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
